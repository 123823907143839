import React, { useState, useEffect } from 'react'
import { useAppSelector } from 'src/js/slice/hooks'
import lo from 'lodash'

import { Paper, Box } from '@mui/material'

import { StandardTableV2, TableColumnType, TableRowType } from 'src/js/component/Base/Table'
import TitleBar from './TitleBar'
import { GotoTopButton } from 'src/js/component/Base/Button'

import FilterDrawerRef from './FilterDrawerRef'

import { formatDateTimeByUS, formatDateTimeByJPNum } from 'src/js/util/utility'

import * as BaseType from 'src/js/type/base'

type Props = {
  title: string // タイトル
  Filter: React.ReactNode // コンポ：フィルター
  columns: TableColumnType[] // カラム定義
  refer: BaseType.Reference // 参照項目
  isDense?: boolean

  onSearch: () => void // イベント：検索
  onCancel: (filterBefore: object) => void
  onClear: () => void

  addFlg?: boolean // 新規イベント有無フラグ
  onAdd?: () => void // イベント：新規

  exportFlg?: boolean // エクスポート有無フラグ
  csv?: {
    fileName: string
    title: string[]
    items: { key: string; type?: string }[]
  }

  custBtnFlg?: boolean // カスタマイズボタン有無フラグ
  CustBtn?: React.ReactNode // コンポ：カスタマイズボタン

  pageCount?: number

  filterKey?: { funcId: string; filterId: string }

  displayMode?: 'card' | 'page'

  tblStickyHeader?: boolean

  setPageNo: (page: number) => void
  setSorting: (sorting: BaseType.Sorting | null) => void
}
const RichFilterTablePanelRefV2 = ({
  title,
  Filter,
  columns,
  refer,
  isDense = true,
  onSearch,
  onCancel,
  onClear,
  addFlg = false,
  onAdd,
  exportFlg = false,
  csv,
  custBtnFlg = false,
  CustBtn,
  pageCount = 100,
  filterKey = { funcId: 'test', filterId: 'test' },
  displayMode = 'card',
  tblStickyHeader = false,
  setPageNo,
  setSorting: setSortingToStore,
}: Props) => {
  const [openFlg, setOpenFlg] = useState<boolean>(false)
  const [tblData, setTblData] = useState<TableRowType[]>([])
  const [paging, setPaging] = useState<BaseType.Pagging>({ page: 0, count: 0, total: 0 })
  const [sorting, setSorting] = useState<BaseType.Sorting | null>(null)
  const state = useAppSelector((state) =>
    refer.partId
      ? lo.get(state, `${refer.funcId}.${refer.partId}.${refer.itemId}`)
      : lo.get(state, `${refer.funcId}.${refer.itemId}`)
  ) as BaseType.ResultTableWithPagingWithSorting<TableRowType[]>
  const filterCount = useAppSelector((state) => state.app.filter.count) as number

  useEffect(() => {
    // 検索処理
    let allDate = state.data
    if (!!state.sorting) {
      allDate = lo.orderBy(state.data, [state.sorting.key], [state.sorting.direction])
    }
    setSorting(state.sorting)
    const pageIdx = state.paging.page > 0 ? state.paging.page - 1 : 0
    const startIdx = pageIdx === 0 ? 0 : pageIdx * pageCount
    setTblData(lo.slice(allDate, startIdx, startIdx + pageCount))
    setPaging({
      page: pageIdx + 1,
      count: allDate.length < pageCount ? 1 : lo.ceil(allDate.length / pageCount),
      total: allDate.length,
    })
  }, [state.data, state.sorting])

  const _onPaging = (pageNo: number) => {
    const startIdx = pageNo === 1 ? 0 : (pageNo - 1) * pageCount + 1 - 1
    let allDate = state.data
    if (!!sorting) {
      allDate = lo.orderBy(state.data, [sorting.key], [sorting.direction])
    }
    setTblData(lo.slice(allDate, startIdx, startIdx + pageCount))
    setPaging({
      page: pageNo,
      count: allDate.length < pageCount ? 1 : lo.ceil(allDate.length / pageCount),
      total: allDate.length,
    })
    setPageNo(pageNo)
  }

  const _getCsv = (): string => {
    // パラメータチェック
    if (!exportFlg || !!!csv) return ''
    if (!!!tblData || lo.isEmpty(tblData)) return ''

    let allDate = state.data
    if (!!sorting) {
      allDate = lo.orderBy(state.data, [sorting.key], [sorting.direction])
    }
    const csvData = allDate.map((row) =>
      csv.items.map((field) => {
        if (lo.has(row, field.key)) {
          let value = lo.get(row, field.key)
          if (field.type === 'UTC') {
            value = formatDateTimeByUS(value, 'UTC')
          } else if (field.type === 'UTC_JP_NUM') {
            value = formatDateTimeByJPNum(value, 'UTC')
          }
          return `"${String(value).replaceAll('"', '""')}"` || ''
        }
        return ''
      })
    )

    return `${csv.title.join(',')}\r\n${csvData.map((row) => row.join(',')).join('\r\n')}`
  }

  const _sortByKey = (key: string, direction: 'asc' | 'desc') => {
    // データ情報設定
    const allDate = lo.orderBy(state.data, [key], [direction])
    setTblData(lo.slice(allDate, 0, pageCount))

    // ページング情報リセット
    const pageNo = 1
    setPaging({
      page: pageNo,
      count: state.data.length < pageCount ? 1 : lo.ceil(state.data.length / pageCount),
      total: state.data.length,
    })
    setPageNo(pageNo)

    // ソーティング情報設定
    const sorting = {
      key: key,
      direction: direction,
    }
    setSorting(sorting)
    setSortingToStore(sorting)

    return
  }

  return (
    <Paper sx={[displayMode === 'page' ? { boxShadow: 0 } : { width: 1, mb: 2 }]}>
      <TitleBar
        // 基本プロパティ
        title={title}
        isLoading={state.isLoading}
        setOpenFlg={setOpenFlg}
        inputFilterCount={filterCount}
        addFlg={addFlg}
        onAdd={onAdd} // 新規
        exportFlg={exportFlg}
        csv={{ fileName: csv?.fileName, getCsv: _getCsv }}
        custBtnFlg={custBtnFlg}
        CustBtn={CustBtn} // カスタマイズボタン
        paging={paging}
        onPaging={_onPaging} // ページング
      />

      {/* トップ行戻り */}
      <GotoTopButton />

      {/* 検索条件フィルター */}
      <FilterDrawerRef
        filterKey={filterKey}
        openFlg={openFlg}
        setOpenFlg={setOpenFlg}
        onOk={onSearch}
        onCancel={onCancel}
        onClear={onClear}
      >
        {Filter}
      </FilterDrawerRef>

      {/* 結果テーブル */}
      <Box sx={[{ px: 2 }, displayMode === 'page' ? { py: 0 } : { pt: 1, pb: 4 }]}>
        <StandardTableV2
          columns={columns}
          isLoading={state.isLoading}
          data={tblData}
          isDense={isDense}
          maxHeight={displayMode === 'page' ? '78vh' : undefined}
          stickyHeader={tblStickyHeader}
          sort={_sortByKey}
          sortKey={sorting}
        />
      </Box>
    </Paper>
  )
}

export default RichFilterTablePanelRefV2
