import React, { useState, useEffect, useRef } from 'react'
import {
  Paper,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'

import CustomCell from './CustomCell'
import * as Style from './style'
import * as Type from './type'
import * as BaseType from 'src/js/type/base'

type OrderProps = {
  active: boolean
  direction: 'asc' | 'desc' | undefined
}
type Props = {
  isLoading: boolean
  columns: Type.Column[]
  data?: Type.Row[]
  sort?: (key: string, direction: 'asc' | 'desc') => void
  isDense?: boolean
  maxHeight?: string
  stickyHeader?: boolean
  sortKey: BaseType.Sorting | null
}
const StandardTableV2 = ({
  isLoading,
  isDense = false,
  columns,
  data = [],
  sort = (key: string, direction: 'asc' | 'desc') => {
    console.log(key, direction)
  },
  maxHeight,
  stickyHeader = false,
  sortKey,
}: Props) => {
  const [orders, setOrders] = useState<OrderProps[]>(
    columns.map(() => {
      return { active: false, direction: undefined }
    })
  )
  // スクロールコントール用
  const topOfTable = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setOrders(
      columns.map((column) => {
        if (!!sortKey && column.key === sortKey.key) {
          return { active: true, direction: sortKey.direction }
        }
        return { active: false, direction: undefined }
      })
    )

    // スクロール場所初期化
    // topOfTable.current?.scrollIntoView({ behavior: 'smooth' })
  }, [data, sortKey])

  // オーダー処理
  const _orderByIndex = (index: number, direction: 'asc' | 'desc') => {
    // 新しいオーダー設定
    const order: OrderProps[] = columns.map(() => {
      return { active: false, direction: 'asc' }
    })
    order[index] = { active: true, direction: direction }
    setOrders(order)

    // ソート処理
    sort(columns[index].key, direction)
  }

  const _getNextDirection = (
    active: boolean,
    direction: 'asc' | 'desc' | undefined
  ): 'asc' | 'desc' => {
    if (!active) return 'asc'
    if (direction === undefined || direction === 'desc') return 'asc'

    return 'desc'
  }

  const _getTabelHeadCellStyle = (col: Type.Column) => {
    let style = {}

    if (!!col.width) {
      style = { ...style, width: col.width }
    }

    return style
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={[maxHeight ? { maxHeight: maxHeight } : { maxHeight: 1 }]}
      >
        {/* スクロールアンカー */}
        <div ref={topOfTable} />
        <Table
          sx={[Style.table, maxHeight ? { maxHeight: maxHeight } : { maxHeight: 1 }]}
          size={isDense ? 'small' : 'medium'}
          stickyHeader={stickyHeader}
        >
          {/* ヘッダー */}
          <TableHead>
            <TableRow>
              {columns.map((col, index) =>
                col.type === 'hidden' ? (
                  <></>
                ) : (
                  <TableCell
                    sx={_getTabelHeadCellStyle(col)}
                    key={index}
                    align={col.align}
                    size={isDense ? 'small' : 'medium'}
                  >
                    {col.sortable === false ? (
                      col.label
                    ) : (
                      <TableSortLabel
                        sx={Style.headerLabel}
                        active={orders[index].active}
                        direction={orders[index].direction}
                        onClick={() => {
                          _orderByIndex(
                            index,
                            _getNextDirection(orders[index].active, orders[index].direction)
                          )
                        }}
                      >
                        {col.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          {/* ボディー */}
          {!isLoading && (
            <TableBody>
              {data.map((row, rowIdx) => (
                <TableRow key={rowIdx}>
                  {columns.map((col, colIdx) =>
                    col.type === 'hidden' ? (
                      <></>
                    ) : (
                      <CustomCell
                        key={colIdx}
                        row={row}
                        col={col}
                        colIdx={colIdx}
                        rowIdx={rowIdx}
                      />
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {isLoading && (
        <Box sx={{ textAlign: 'center', pt: 1 }}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

export default StandardTableV2
