import React from 'react'
// import React, { useEffect } from 'react'
import { Link, TableCell } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import lo from 'lodash'
// import { SxProps, Theme } from '@mui/material/styles'

import * as Type from './type'

type Props = {
  row: Type.Row
  col: Type.Column
  colIdx: number
  rowIdx: number
}
const CustomCell = ({ row, col, colIdx, rowIdx }: Props) => {
  const navigate = useNavigate()

  // useEffect(() => {
  // }, [row, col, colIdx, rowIdx])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _goto = (pathname: string, state: any) => {
    navigate(pathname, { state: state })
  }

  if (col.type === 'link') {
    return (
      <TableCell key={`${rowIdx}-${colIdx}`} align={col.align}>
        <Link
          component='button'
          onClick={() => {
            _goto(col.to || '', col.param)
          }}
        >
          {lo.get(row, col.key)}
        </Link>
      </TableCell>
    )
  }

  if (col.type === 'button') {
    return (
      <TableCell key={`${rowIdx}-${colIdx}`} align={col.align}>
        <Link
          component='button'
          onClick={() => col.onClick && col.onClick(row)}
          sx={!!col.lineBreak ? { whiteSpace: 'pre' } : undefined}
        >
          {!!lo.get(row, col.key)
            ? !!col.format
              ? col.format(lo.get(row, col.key))
              : lo.get(row, col.key)
            : col.defaultValue}
        </Link>
      </TableCell>
    )
  }

  if (col.type === 'custom') {
    return (
      <TableCell key={`${rowIdx}-${colIdx}`} align={col.align}>
        {!!col.custom && col.custom(row, rowIdx)}
      </TableCell>
    )
  }

  return (
    <TableCell key={`${rowIdx}-${colIdx}`} align={col.align}>
      {!!col.format && col.format(lo.get(row, col.key))}
      {!!!col.format && lo.get(row, col.key)}
    </TableCell>
  )
}

export default CustomCell
