import * as Type from 'src/js/type/base'
import lo from 'lodash'
// import { connect } from 'react-redux';
import { REGULAR_EXPR } from './constants'

// NULL判別
export const isEmpty = (obj: object) => obj === null || obj === undefined || '' + obj === ''

// 機能権限取得
export const getFuncAuth = (funcId: string, appAuth: Type.Auth[]): Type.AuthKbn => {
  return lo.find(appAuth, { funcId: funcId })?.auth || 'NG'
}

// Readonly判別
export const isReadOnly = (roleType: Type.AuthKbn) => {
  if (!!roleType && roleType === 'RW') return false
  return true
}

// 正規表現
export const isMatch = (pStr: string, pRegExp: string): boolean => {
  //パラメータチェック
  if (!pStr || !pRegExp) return false
  try {
    const rst = pStr.match(pRegExp)
    return !!rst && !lo.isEmpty(rst)
  } catch (ex) {
    return false
  }
}

// 金額フォーマット
export const formatCurrency = (pStr: string): string | undefined => {
  // パラメータチェック
  if (!pStr) return

  const value = lo.replace(pStr, new RegExp(/,/g), '')
  if (!isMatch(value, REGULAR_EXPR.HALF_N)) return

  return lo.toNumber(value).toLocaleString()
}

// 日付フォーマット変換（YYYYMMDD）
export const convertToYMDByUTC = (date: Date): string => {
  if (!!!date) return ''

  return date.toISOString().slice(0, 10).replace(/-/g, '')
}

// 日付フォーマット変換（YYYYMMDD）
export const convertToYMD = (date: Date): string => {
  if (!!!date) return ''

  const year = date.getFullYear()
  const month = ('00' + (date.getMonth() + 1)).slice(-2)
  const day = ('00' + date.getDate()).slice(-2)

  return `${year}${month}${day}`
}

// 日付フォーマット変換（YYYYMMDD）
export const convertToYMDHyphenByUTC = (date: Date): string => {
  if (!!!date) return ''

  return date.toISOString().slice(0, 10)
}

// フィルター・デフォルト・日付範囲取得
export const getFilterDateRange = (): { start: string; end: string } => {
  const startDate = new Date()
  startDate.setMonth(startDate.getMonth() - 3)

  const endDate = new Date()
  endDate.setHours(endDate.getHours() + 24)

  return { start: convertToYMDHyphenByUTC(startDate), end: convertToYMDHyphenByUTC(endDate) }
}

export const formatDateByUSByJST = (value: string): string => {
  if (!!!value) return ''

  const strDate = _convertDateFormate(value)
  return new Date(strDate).toLocaleDateString('en-US', {
    timeZone: 'JST',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })
}

export const formatDateByJPShort = (value: string): string => {
  if (!!!value) return ''

  const strDate = _convertDateFormate(value)
  return new Date(strDate).toLocaleDateString('ja-JP', {
    timeZone: 'Asia/Tokyo',
  })
}

export const formatDateTimeByUSShort = (value: string): string => {
  if (!!!value) return ''

  const strDate = _convertDateFormate(value)
  return new Date(strDate).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })
}

export const formatLocalDateTimeByUS = (value: string): string => {
  if (!!!value) return ''

  const strDate = _convertDateFormate(value)
  return new Date(strDate).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    second: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    hour12: false,
  })
}

export const formatDateTimeByUS = (value: string, timeZone: 'UTC' | 'JST'): string => {
  if (!!!value) return ''

  const strDate = _convertDateFormate(value)
  return new Date(strDate).toLocaleDateString('en-US', {
    timeZone: timeZone,
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    second: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    hour12: false,
  })
}

export const formatDateTimeByJPNum = (value: string, timeZone: 'UTC' | 'JST'): string => {
  if (!!!value) return ''

  const strDate = _convertDateFormate(value)
  return new Date(strDate).toLocaleDateString('ja-JP', {
    timeZone: timeZone,
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    minute: 'numeric',
    hour: 'numeric',
  })
}

export const toDateByYMD = (value: string): Date | undefined => {
  if (!!!value || value.length !== 8) return

  return new Date(_convertDateFormate(value))
}

const _convertDateFormate = (date: string): string => {
  if (!!!date) return date

  if (date.length === 8) {
    return date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)
  }

  if (date.length === 14) {
    return (
      date.substring(0, 4) +
      '-' +
      date.substring(4, 6) +
      '-' +
      date.substring(6, 8) +
      'T' +
      date.substring(8, 10) +
      ':' +
      date.substring(10, 12) +
      ':' +
      date.substring(12, 14) +
      'Z'
    )
  }

  return date
}

export const countFilter = (filter: object): number => {
  let count = 0
  lo.forEach(filter, (value) => {
    if (!!value && value !== '' && value !== null && value !== undefined) {
      count = count + 1
    }
  })
  return count
}

// ファイル名取得
export const getFileName = (fileFullName: string): string => {
  if (!!!fileFullName) return ''

  const regx = fileFullName.match(/_/g)
  if (!!!regx || regx.length < 3) return fileFullName

  const index = fileFullName.indexOf('_')
  if (index === -1) return fileFullName

  return fileFullName.substring(index + 1)
}

export const formatDate = (dt: Date): string => {
  if (!!!dt) return ''

  const year = dt.getFullYear()
  const month = ('00' + (dt.getMonth() + 1)).slice(-2)
  const day = ('00' + dt.getDate()).slice(-2)

  return year + '-' + month + '-' + day
}
