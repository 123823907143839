import React, { useEffect, useState, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'src/js/slice/hooks'
import { useLocation } from 'react-router-dom'

import { StdPanel, RichFilterTablePanelRef } from 'src/js/component/Base/Panel'
import { TableColumnType, TableRowType } from 'src/js/component/Base/Table'
import Header from './Header'
import Filter from './Filter'
import Input from './Input'

import {
  header,
  initFilter,
  search,
  gc,
  cancelFilter,
  clearFilter,
  initInput,
  input,
  update,
  deleteById,
} from 'src/js/slice/B0012'
import { isReadOnly, getFuncAuth } from 'src/js/util/utility'

import { B0012 as RESOURCE } from 'src/resource'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import { formatDateTimeByUS } from 'src/js/util/utility'
import { AuthKbn as AuthKbnType } from 'src/js/type/base'

const B0012 = () => {
  const [open, setOpen] = useState<boolean>(false)
  const appAuth = useAppSelector((state) => state.app.auth)
  const funcAuth = useMemo(() => getFuncAuth(FUNC.B0012, appAuth), [appAuth]) as AuthKbnType

  const headerIsLoading = useAppSelector((state) => state.B0012.header.isLoading)
  const inputLoading = useAppSelector((state) => state.B0012.input.isLoading)
  const inputMode = useAppSelector((state) => state.B0012.input.mode)

  const dispatch = useAppDispatch()
  const location = useLocation()
  const imoNo = (location.state as { p1: string }).p1

  // 初期化
  useEffect(() => {
    // ヘッダー情報取得
    dispatch(header(imoNo))
    ;(async () => {
      // 検索条件初期化
      await dispatch(initFilter())

      // 検索処理
      await dispatch(search(imoNo))
    })()

    // ガベージコレクション
    return () => {
      dispatch(gc())
    }
  }, [])

  // 画面クローズ
  const _onClose = () => {
    setOpen(false)
  }

  // 登録
  const _onOK = () => {
    ;(async () => {
      await dispatch(update(imoNo)) // 更新
      await dispatch(search(imoNo)) // 再検索
    })()
    setOpen(false)
  }

  // 編集画面開く
  const _onOpenEdit = (row: TableRowType) => {
    setOpen(true)
    dispatch(input(row['id']))
  }

  // 新規画面開く
  const _onOpenNew = () => {
    setOpen(true)
    dispatch(initInput())
  }

  // 削除
  const _onDelete = () => {
    ;(async () => {
      await dispatch(deleteById(imoNo)) // 削除
      await dispatch(search(imoNo)) // 再検索
    })()
    setOpen(false)
  }

  // カラム定義
  const columns = [
    {
      key: 'createdAt',
      label: RESOURCE.LBL_CREATED_TIME,
      align: 'left',
      format: (value: string) => formatDateTimeByUS(value, 'UTC'),
      type: 'button',
      onClick: (row) => {
        _onOpenEdit(row)
      },
    },

    { key: 'userId', label: RESOURCE.LBL_AUTHOR, align: 'left' },
    {
      key: 'comment',
      label: RESOURCE.LBL_COMMENT,
      align: 'left',
      type: 'button',
      lineBreak: true,
      onClick: (row) => {
        _onOpenEdit(row)
      },
    },
  ] as TableColumnType[]

  return (
    <>
      {/* ロール情報 */}
      <StdPanel title={RESOURCE.TITLE_INPUT_HEADER} isLoading={headerIsLoading}>
        <Header />
      </StdPanel>

      {/* フィルターテーブル */}
      <RichFilterTablePanelRef
        title={RESOURCE.TITLE}
        Filter={<Filter />}
        columns={columns}
        refer={{ funcId: FUNC.B0012, itemId: 'result' }}
        onSearch={() => {
          dispatch(search(imoNo))
        }}
        onCancel={(filterPrev) => {
          dispatch(cancelFilter(filterPrev))
        }}
        onClear={() => {
          dispatch(clearFilter())
        }}
        filterKey={{ funcId: FUNC.B0012, filterId: 'filter' }}
        addFlg={!isReadOnly(funcAuth)}
        onAdd={_onOpenNew}
        // displayMode='page'
        // tblStickyHeader={true}
      />

      {/* 入力ダイアログ */}
      <Input
        open={open}
        onClose={_onClose}
        onOK={_onOK}
        onDelete={_onDelete}
        isLoading={inputLoading}
        mode={isReadOnly(funcAuth) ? 'view' : inputMode}
      />
    </>
  )
}
export default B0012
