import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'src/js/slice/store'
import lo from 'lodash'

import { setFilterCount } from 'src/js/slice/App'
import { query, mutation } from 'src/js/util/graphql'
import * as Api from './api'

import { FUNCTION_ID as FUNC, FILE_STATUS } from 'src/js/util/constants'
import { countFilter } from 'src/js/util/utility'
import * as Type from './type'

// 非同期処理定義
// 更新処理
type updateFileStatusOutProps = {
  id: string
  fileStatus: string
}
type updateFileStatusInProps = {
  index: number
  id: string
  imoNo: string
}
export const updateFileStatus = createAsyncThunk<
  updateFileStatusOutProps,
  updateFileStatusInProps,
  { state: RootState }
>(`${FUNC.B0011}/updateFileStatus `, async (param, thunkAPI) => {
  try {
    return await mutation<updateFileStatusInProps, updateFileStatusOutProps>(
      thunkAPI.dispatch,
      param,
      async (dispatch, param) => {
        // 更新処理
        await Api.updateFileStatus(param.id, param.imoNo, FILE_STATUS.CONFIRMED_ERROR)
        return { id: param.id, fileStatus: FILE_STATUS.CONFIRMED_ERROR }
      }
    )
  } catch (err) {
    console.log(err)
    return thunkAPI.rejectWithValue({})
  }
})

// 更新処理
type updateSNConfirmFlagInProps = {
  id: string
  value: boolean
}
type updateSNConfirmFlagMutationInProps = {
  version: string
  value: boolean
  userId: string
}
export const updateSNConfirmFlag = createAsyncThunk<
  null,
  updateSNConfirmFlagInProps,
  { state: RootState }
>(`${FUNC.B0011}/updateSNCofirmFlag `, async (param, thunkAPI) => {
  try {
    const index = parseInt(new String(param.id).replace('confirmedFlag_', ''))
    const data = thunkAPI.getState().B0011.result.data[index]
    const userId = thunkAPI.getState().app.user?.userId
    if (!!!data.version || !!!userId) return null

    return await mutation<updateSNConfirmFlagMutationInProps, null>(
      thunkAPI.dispatch,
      {
        version: data.version,
        value: param.value,
        userId: userId,
      },
      async (dispatch, param) => {
        // 更新処理
        await Api.updateSNConfirm(param.version, param.value ? '1' : '0', userId)
        return null
      }
    )
  } catch (err) {
    return thunkAPI.rejectWithValue({})
  }
})

// 検索処理
export const search = createAsyncThunk<Type.FileInfo[], string, { state: RootState }>(
  `${FUNC.B0011}/search`,
  async (imoNo, thunkAPI) => {
    try {
      return await query<Type.Filter, Type.FileInfo[]>(
        thunkAPI.dispatch,
        thunkAPI.getState().B0011.filter,
        async (dispatch, filter) => {
          // フィルターカウンター
          dispatch(setFilterCount(countFilter(filter)))

          // 検索処理
          return await Api.search(imoNo, filter)
        }
      )
    } catch (err) {
      return thunkAPI.rejectWithValue({})
    }
  }
)

// フィルター初期化
const _getInitFilter = (): Type.Filter => {
  // 初期設定
  const filter = getInitialState().filter

  // const range = getFilterDateRange()
  // filter.uploadedDateF = range.start
  // filter.uploadedDateT = range.end

  return filter
}

// Slice定義
const getInitialState = (): Type.State => ({
  // 検索条件
  filter: {
    imoNo: '',
    fileType: '',
    status: '',
    fileName: '',
    checkedDate: '',
    uploadedDateF: '',
    uploadedDateT: '',
    version: '',
  },

  // 検索結果
  result: {
    isLoading: false,
    data: [],
    paging: {
      count: 0,
      page: 0,
      total: 0,
    },
    sorting: null,
  },
})
export const Slice = createSlice({
  name: FUNC.B0011,
  initialState: getInitialState(),
  reducers: {
    initFilter: (state) => {
      state.filter = _getInitFilter()
    },
    setFilter: (state, action) => {
      const { id, value } = action.payload
      lo.set(state.filter, id, value)
    },
    clearFilter: (state) => {
      state.filter = _getInitFilter()
    },
    cancelFilter: (state, action) => {
      state.filter = action.payload
    },
    gc: (state) => {
      const initState = getInitialState()
      state.filter = initState.filter
      state.result = initState.result
    },
    setPageNo: (state, action) => {
      const pageNo = action.payload as number
      state.result.paging.page = pageNo
    },
    setSorting: (state, action) => {
      const sorting = action.payload
      state.result.sorting = sorting
    },
  },
  extraReducers: (builder) => {
    builder
      // 検索処理
      .addCase(search.pending, (state) => {
        state.result.isLoading = true
      })
      .addCase(search.fulfilled, (state, action) => {
        state.result.isLoading = false
        state.result.data = action.payload
        state.result.paging = {
          count: 0,
          page: 0,
          total: 0,
        }
        state.result.sorting = null
      })
      .addCase(search.rejected, (state) => {
        state.result = getInitialState().result
      })

      // SNComfirmFlag更新
      .addCase(updateSNConfirmFlag.pending, () => {
        // 処理なし
      })
      .addCase(updateSNConfirmFlag.fulfilled, () => {
        // 処理なし
      })
      .addCase(updateSNConfirmFlag.rejected, () => {
        // 処理なし
      })

      // FileStatus更新
      .addCase(updateFileStatus.pending, () => {
        // 処理なし
      })
      .addCase(updateFileStatus.fulfilled, (state, action) => {
        const { id, fileStatus } = action.payload

        // 対象インディクス取得
        const data = lo.cloneDeep(state.result.data)
        const idx = lo.findIndex(data, (fileInfo) => fileInfo.id === id)
        if (idx === -1) return

        // ステータス更新
        data[idx].fileStatus = fileStatus
        state.result.data = data
      })
      .addCase(updateFileStatus.rejected, () => {
        // 処理なし
      })
  },
})

export const { initFilter, setFilter, clearFilter, cancelFilter, gc, setPageNo, setSorting } =
  Slice.actions

export default Slice.reducer
