import React, { useMemo } from 'react'
import { useAppSelector } from 'src/js/slice/hooks'

import { setInput } from 'src/js/slice/B0012'
import { FormDialog } from 'src/js/component/Base/Dialog'
import { FormCtnGrid, FormItmGrid } from 'src/js/component/Base/Grid'
import { StdTextAreaRef } from 'src/js/component/Base/TextField'
import { FUNCTION_ID as FUNC } from 'src/js/util/constants'
import { B0012 as RESOURCE } from 'src/resource'

import * as Type from 'src/js/type/base'

type Props = {
  open: boolean
  mode: Type.DialogStatus
  onOK: () => void
  onClose: () => void
  onDelete: () => void
  isLoading?: boolean
}
const Input = ({ open, mode, onOK, onClose, onDelete, isLoading = true }: Props) => {
  const appUserId = useAppSelector((state) => state.app.user?.userId)
  const userId = useAppSelector((state) => state.B0012.input.userId)
  const dispMode = useMemo(
    () => ((mode === 'edit' || mode === 'delete') && appUserId !== userId ? 'view' : mode),
    [userId, mode]
  ) as Type.DialogStatus
  // const dispMode = useMemo(
  //   () => (appUserId !== userId ? 'view' : mode),
  //   [userId, mode]
  // ) as Type.DialogStatus

  return (
    <FormDialog
      title={RESOURCE.TITLE_INPUT}
      mode={dispMode}
      isLoading={isLoading}
      open={open}
      onCancel={onClose}
      onOK={onOK}
      onDelete={onDelete}
      updBtnFlg={false}
      width={'md'}
    >
      <FormCtnGrid>
        {/* COMMENT */}
        <FormItmGrid>
          <StdTextAreaRef
            id='comment'
            label={RESOURCE.LBL_COMMENT}
            size='small'
            refer={{ funcId: FUNC.B0012, partId: 'input', itemId: 'comment' }}
            onChange={setInput}
            disabled={mode !== 'new'}
            dispRows={10}
          />
        </FormItmGrid>
      </FormCtnGrid>
    </FormDialog>
  )
}
export default Input
